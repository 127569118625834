import React, {Suspense, lazy, Component} from 'react'
import {Spinner} from 'react-bootstrap'
import {initSentry} from './service'
import {Route, BrowserRouter, Routes} from 'react-router-dom'
import './App.css'

// Custom elements
// const MainScreen = lazy(() => import('./screens/Main'))
// const SuccessScreenScreen = lazy(() => import('./screens/OrderSuccess'))
// const OrderCanceledScreen = lazy(() => import('./screens/OrderCanceled'))
const CardViewerScreen = lazy(() => import('./screens/CardViewer'))
const ErrorScreen = lazy(() => import('./screens/Error'))
const DownloadDigitalPassScreen = lazy(
  () => import('./screens/DownloadApplePass')
)

const NotTakingNewOrdersScreen = lazy(
  () => import('./screens/NotTakingNewOrders')
)

// Start Sentry for error reporting
initSentry()

class App extends Component<any, any> {
  render() {
    return (
      <BrowserRouter>
        <Suspense
          fallback={
            <div
              style={{
                minHeight: '100vh',
                color: 'white',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}>
              <Spinner animation="border" role="status" />
              <p>Loading...</p>
            </div>
          }>
          <Routes>
            <Route path="/">
              <Route index element={<NotTakingNewOrdersScreen />} />
              {/* <Route path="order-success" element={<SuccessScreenScreen />} />
              <Route path="order-canceled" element={<OrderCanceledScreen />} /> */}
              <Route path="card/:uuid" element={<CardViewerScreen />} />
              <Route
                path="download/apple-pass/:uuid"
                element={<DownloadDigitalPassScreen />}
              />
              <Route path="*" element={<ErrorScreen />} />
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    )
  }
}

export default App
