import * as Sentry from '@sentry/react'
import SentryRRWeb from '@sentry/rrweb'
import {Integrations} from '@sentry/tracing'
import CONFIG from '../config'

export const initSentry = () => {
  Sentry.init({
    dsn: 'https://c182c19a20df43cfb005a3e9eb53322c@o819469.ingest.sentry.io/5835341',
    integrations: [new Integrations.BrowserTracing(), new SentryRRWeb()],
    tracesSampleRate: 1.0,
    environment: CONFIG.SENTRY_ENV,
    beforeSend(event, hint) {
      if (event.exception) {
        const handled = () => {
          if (event.exception?.values) {
            const val = event.exception.values[0].mechanism?.handled
            return val
          }
        }
        if (!handled()) {
          // Sentry.showReportDialog({ eventId: event.event_id });
        }
      }
      return event
    },
  })
}
