import amplitude from 'amplitude-js'
import {config} from '../config'

export const initAmplitude = () => {
  amplitude.getInstance().init(config.AMPLITUDE_API_KEY)
}

// Generic Event
export const amplitudeLogCustomEvent = (
  eventType: any,
  eventProperties?: any
) => {
  amplitude.getInstance().logEvent(eventType, eventProperties)
}

// Uses the phone number to set the unique user identifier
export const amplitudeSetUser = (uuid: string) =>
  amplitude.getInstance().setUserId(uuid)

// Sets the phone number as a user property
export const amplitudeSetPhoneNumber = (number: string) =>
  amplitude.getInstance().setUserProperties({number})

export enum AmplitudeScannerEventOptions {
  TOTAL_DOCUMENT_DETECTED_COUNT, // This is the total number of times the scanner detected something it thought was a document
  TOTAL_PROCESSED_SCANS, // Total number of times it tried to process an image
  SUCCESSFUL_SCAN_COUNT, // This is the number of times it had the right aspect ratio and passed all tests
  TOTAL_RESCANS, // Number of times the user pressed rescan

  SCANNING_TIME,

  // Textract data
  BLURRY_SCAN_COUNT,
}

export enum AmplitudeEventOptions {
  LOADED_DESKTOP,
  LOADED_MOBILE,
  REQUESTED_SMS_LINK,

  SCANBOT_EXPIRED_LICENSE, // Detects when scanbot has an expired license

  CLICKED_BEGIN_BUTTON, // Page 1 Starting
  CLICKED_CONTINUE_BUTTON, // Page 2 Continue Button

  PRESSED_ORDER_ANOTHER_VAXCARD_BUTTON, // Order another vaxcard after purchase

  // Scanner
  OPENED_SCANNER,

  FIRST_VALID_CARD,
  CLICKED_CLOSE_SCANNER_BUTTON, // Close Scanner Button

  RESCAN, // Deprecated -> moved over to TOTAL_RESCANS so we have a count not just a boolean

  CLICKED_SCAN_BUTTON, // Button -> Clicked Scan

  DELETE_VACCINE_IMAGE,

  // Other Actions
  CLICKED_CHECKOUT_BUTTON,
  AGREED_TO_TERMS,
  ADDED_PHONE_NUMBER,

  // Payment
  PAYMENT_SUCCESS,
  PAYMENT_CANCELED,

  // Terms & Service Events
  CLICKED_TERMS_OF_SERVICE_BUTTON,
  CLICKED_PRIVACY_POLICY_BUTTON,

  // Pressed Share on Facebook Button
  CLICKED_SHARE_ON_FACEBOOK_BUTTON,

  BLURY_SCAN,

  // Scanbot functions
  SCANBOT_DETECTED_CARD_WITH_VALID_ASPECT_RATIO,
  SCANBOT_DETECTED_CARD_WITHOUT_BLUR,

  // Scanbot SDK
  SCANBOT_SDK_LOAD_WAS_SUCCESSFUL,
  SCANBOT_SDK_LOAD_WAS_UNSUCCESSFUL,

  // Scanbot document scanner
  SCANBOT_DOCUMENT_SCANNER_LOAD_WAS_SUCCESSFUL,
  SCANBOT_DOCUMENT_SCANNER_LOAD_WAS_UNSUCCESSFUL,

  // Scanbot Error Types:

  // UnsupportedMediaDevicesError
  SCANBOT_UNSUPPORTED_MEDIA_DEVICES_ERROR,

  // MediaPermissionError
  SCANBOT_MEDIA_PERMISSION_ERROR,

  // MediaNotAvailableError
  SCANBOT_MEDIA_NOT_AVAILABLE_ERROR,

  // UnknownError
  SCANBOT_UNKNOWN_ERROR,

  // Scanbot Permission Error Actions
  PERMISSION_ERROR_ACTION_PRESSED_COPY_TO_CLIPBOARD,
  PERMISSION_ERROR_ACTION_PRESSED_TEXT_A_LINK,
}

export const amplitudeLogEvent = (type: AmplitudeEventOptions) => {
  amplitude.getInstance().logEvent(AmplitudeEventOptions[type])
}

export const amplitudeLogSDKInitTime = (time: number) => {
  amplitude.getInstance().setUserProperties({SCANBOT_INIT_TIME: time})
}

export type Status = 'LOADING' | 'SUCCESS' | 'FAILURE'
export const amplitudeLogDocumentScannerInitTime = (
  time: number,
  status: Status
) => {
  amplitude
    .getInstance()
    .setUserProperties({DOC_SCANNER_INIT_TIME: time, status})
}

export const amplitudeLogScannerEvent = (
  type: AmplitudeScannerEventOptions,
  count: number
) =>
  amplitude
    .getInstance()
    .setUserProperties({[AmplitudeScannerEventOptions[type]]: count})

// Get average from array of numbers
const totalTime = (array: number[]) => array.reduce((a, b) => a + b)

export const amplitudeLogScanningTimes = (times: number[]) => {
  const totalScanningTime = totalTime(times)
  const averageScanningTime = totalScanningTime / times.length

  amplitude.getInstance().setUserProperties({
    AVERAGE_SCANNING_TIME: averageScanningTime,
    TOTAL_TIME_SCANNING: totalScanningTime,
    SCANNING_ATTEMPT_TIMES: times,
  })
}
