// Note: All env variables used in react apps need "REACT_APP_" at the front of the key

const env = process.env.NODE_ENV

export function getAnalyticsEnv(branch: string, reviewId: string): string {
  if (branch === 'master') {
    return 'production'
  }

  if (branch === 'staging') {
    return 'staging'
  }

  return `vaxcard-pr-${reviewId}`
}

export function getBackendUrl(branch: string, reviewId: string): string {
  if (branch === 'master') {
    return 'https://production.vaxcard.com'
  }

  // NOTE not sure what url this should be
  if (branch === 'staging') {
    return 'https://vaxcard-staging.herokuapp.com'
  }

  if (reviewId !== '') {
    return `https://vaxcard-pr-${reviewId}.herokuapp.com`
  }

  return 'http://localhost:3001'
}

export function getFrontendUrl(branch: string, reviewId: string): string {
  if (branch === 'master') {
    return 'https://go.vaxcard.com'
  }

  // NOTE not sure what url this should be
  if (branch === 'staging') {
    return 'https://vaxcard-staging.herokuapp.com'
  }

  if (reviewId !== '') {
    return `https://deploy-preview-${reviewId}--vaxcard.netlify.app`
  }

  return 'http://localhost:3000'
}

export function getScanbotLicense(branch: string): string {
  if (branch === 'master') {
    return process.env.REACT_APP_SCANBOT_LICENSE_KEY || ''
  }
  return ''
}

export function getPostGridApiKey(branch: string) {
  if (branch === 'master') {
    return process.env.REACT_APP_POSTGRID_PRODUCTION_KEY || ''
  } else if (branch === 'staging') {
    return process.env.REACT_APP_POSTGRID_STAGING_KEY || ''
  } else {
    return process.env.REACT_APP_POSTGRID_TEST_KEY || ''
  }
}

const REVIEW_ID: string = process.env.REACT_APP_REVIEW_ID || ''
const BRANCH: string = process.env.REACT_APP_BRANCH || ''

const POSTGRID_API_KEY = getPostGridApiKey(BRANCH)
const SCANBOT_LICENSE_KEY = getScanbotLicense(BRANCH)
const AMPLITUDE_API_KEY: string = process.env.REACT_APP_AMPLITUDE_API_KEY || ''
const AMPLITUDE_ENV = getAnalyticsEnv(env, REVIEW_ID)
const SENTRY_ENV = getAnalyticsEnv(env, REVIEW_ID)

const BACKEND_URL = getBackendUrl(BRANCH, REVIEW_ID)
const FRONTEND_URL = getFrontendUrl(BRANCH, REVIEW_ID)

type appConfig = {
  ENVIRONMENT: string
  POSTGRID_API_KEY: string
  SCANBOT_LICENSE_KEY: string
  AMPLITUDE_API_KEY: string
  AMPLITUDE_ENV: string
  SENTRY_ENV: string
  BRANCH: string
  BACKEND_URL: string
  FRONTEND_URL: string
}

export const config: appConfig = {
  ENVIRONMENT: env,
  POSTGRID_API_KEY,
  SCANBOT_LICENSE_KEY,
  AMPLITUDE_API_KEY,
  AMPLITUDE_ENV,
  SENTRY_ENV,
  BRANCH,
  BACKEND_URL,
  FRONTEND_URL,
}

//console.log('config:', config)

export default config
